import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Bibliography, InlineEquation, Figure } from '@aeaton/react-paper';
import smoke from './data/smoke.jpg';

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <section><h1 parentName="section">{`Introduction`}</h1><p parentName="section">{`This is the introduction `}<cite parentName="p" {...{
          "items": "saad2013supporting;qiao2013cociter"
        }}></cite>{`.`}</p></section>
    <section><h1 parentName="section">{`Results`}</h1><p parentName="section">{`These are the results `}<cite parentName="p" {...{
          "items": "qiao2013cociter;rind2017pubviz"
        }}></cite>{`.`}</p><p parentName="section">{`This is an example of an inline equation: `}<InlineEquation tex="\int_0^\infty x^2 dx" mdxType="InlineEquation" />{`.`}</p><p parentName="section">{`This is a subscript: CO`}<sub parentName="p">{`2`}</sub>{`.`}</p><Figure label={'Figure 1'} title={'An example figure'} caption={'This is an example figure.'} mdxType="Figure">
  <img src={smoke} />
      </Figure></section>
    <section><h1 parentName="section">{`Discussion`}</h1><p parentName="section">{`This is the discussion `}<cite parentName="p" {...{
          "items": "qiao2013cociter;saad2013supporting"
        }}></cite>{`.`}</p></section>
    <section><h1 parentName="section">{`Bibliography`}</h1><Bibliography mdxType="Bibliography" />export const _frontmatter = {}</section>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      